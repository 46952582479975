:root {
  --color--white: #fff;
  --color--black: #000;
  --font-family--everett: "everett", sans-serif;
  --font-family--times: "Times New Roman", Times, serif;
}

@media (max-width: 768px) {
  :root {
    --font-size--everett: 1rem;
    --font-size--times: 1rem;
  }
}

html, body {
  height: 100%;
  overflow: hidden;
}

* {
  color: #fff;
}

h1, h2 {
  color: #fff;
  text-decoration: none;
}

img {
  width: initial;
  height: initial;
  padding-top: initial;
  left: initial;
}

.issue-03--section {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: #000;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.issue-03--section.active {
  opacity: 1;
  pointer-events: all;
}

.loading--content {
  font-family: var(--font-family--everett);
  opacity: .8;
  font-size: 3rem;
}

.issue-03--section button, .loading button {
  font-family: var(--font-family--everett);
  cursor: pointer;
  background: none;
  border: none;
  font-size: 2rem;
  transition: opacity .3s ease-in-out;
}

.issue-03--section button, .loading button:hover, .issue-03--section button:hover {
  opacity: .6;
}

.issue-03--section .questionnaire--label {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.5rem;
}

.issue-03--section .video--logo {
  opacity: 0;
  width: 7rem;
  z-index: 5;
  position: fixed;
  top: 1rem;
  left: 1rem;
}

.issue-03--section .video--logo img {
  width: 100%;
}

.issue-03--section .questionnaire--input {
  max-width: 40ch;
  color: #fff;
  font-family: var(--font-family--everett);
  background: none;
  border-style: solid;
  border-color: #fff;
  border-radius: 1rem;
  padding: .75rem;
  font-size: 1rem;
}

.issue-03--section .questionnaire--input:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  outline: none;
  overflow: auto;
}

.issue-03--section .questionnaire--input::placeholder {
  color: #fff;
  font-family: var(--font-family--everett);
}

.issue-03--section .questionnaire--submit {
  font-size: 1.5rem;
  font-family: var(--font-family--times);
  margin-top: 1rem;
  transition: opacity .3s ease-in-out;
}

.issue-03--section .questionnaire--submit:hover {
  opacity: 1;
}

.issue-03--results {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: #000;
  font-family: everett, sans-serif;
  font-size: 1.75rem;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.issue-03--results.active {
  opacity: 1;
  pointer-events: all;
}

.issue-03--results .results {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.issue-03--results .main-header {
  font-size: 3.5rem;
}

.issue-03--results .questionnaire-question {
  font-size: 2rem;
}

.issue-03--results .questionnaire-answer {
  opacity: .8;
  font-size: 1.75rem;
  font-family: var(--font-family--times);
}

.issue-03-results .exit-link {
  color: #fff;
  font-size: 1rem;
  font-family: var(--font-family--times);
}

.issue-03--section .video, .issue-03--section .video-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.issue-03--section .video img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.issue-03--section form {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease-in-out;
  display: flex;
  position: relative;
}

.issue-03--section form.active {
  opacity: 1;
  pointer-events: all;
}

xw .issue-03--section form mux-player {
  z-index: -1;
  pointer-events: none;
}

mux-player {
  pointer-events: none;
  --controls: none;
  --media-object-fit: cover;
  z-index: 0;
  position: absolute;
  inset: 0;
}

mux-player::part(video) {
  pointer-events: none;
}

mux-player::part(gesture-layer) {
  display: none;
}

::cue {
  color: #fff;
  font-family: "";
  font-size: "Times New Roman", Times, serif;
  padding-bottom: 1rem;
}

@media (max-width: 768px) {
  .loading--content {
    font-size: 1.5rem;
  }

  .loading--content button {
    font-size: 1.25rem;
  }
}

/*# sourceMappingURL=index.af75fe3c.css.map */
